import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Layout from "../../../components/chat-channel/Layout";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import { useMedia } from "use-media";
import { BlogCta } from "./helpdesk-problems-solutions";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

import "react-alice-carousel/lib/alice-carousel.css";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/slack/blog_image.png");

const header_image_mobile = require("../../../assets/img/blogs/igc_blog_header_mob.png");
const header_image = require("../../../assets/img/blogs/igc_blog_header.png");
const section_1 = require("../../../assets/img/blogs/igc_blog_img_1.png");
const section_2 = require("../../../assets/img/blogs/igc_blog_img_2.png");
const section_3 = require("../../../assets/img/blogs/igc_blog_img_3.png");
const section_4 = require("../../../assets/img/blogs/igc_blog_img_4.png");
const section_5 = require("../../../assets/img/blogs/igc_blog_img_5.png");
const section_6 = require("../../../assets/img/blogs/igc_blog_img_6.png");
const section_7 = require("../../../assets/img/blogs/igc_blog_img_7.png");
const section_8 = require("../../../assets/img/blogs/igc_blog_img_8.png");

const cta_1 = require("../../../assets/img/blogs/tsvg_cta_1.png");
const cta_2 = require("../../../assets/img/blogs/tsvg_cta_2.png");
const cta_1_mob = require("../../../assets/img/blogs/tsvg_cta_1_mob.png");
const cta_2_mob = require("../../../assets/img/blogs/tsvg_cta_2_mob.png");
const arrow_open = require("../../../assets/images/testimonials/arrow_open_blog.svg");
const arrow_close = require("../../../assets/images/testimonials/arrow_close_blog.svg");
const author = require("../../../assets/img/blogs/deepa_2.png");
const author_mobile = require("../../../assets/img/blogs/mobile_deepa_2.png");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  const isSmall = useMedia({ maxWidth: "520px" });

  const backgroundImage = isSmall ? header_image_mobile : header_image;
  return (
    <>
      <TitleAndMetaTags
        title="SMB Guide: Generative AI Chatbots"
        description="SMB-friendly guide to GenAI chatbots with Workativ Knowledge AI."
        keywords={[
          "service desk chatbot",
          "AI-powered service desk assistant",
          "Chatbot for IT service desk",
        ]}
        ogImage={header_image}
        schema={ldSchema}
      />

      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <section
            className={`w-100 float-left blog_top_header_upt igc_blog_header_upt ${
              isSmall ? "align-items-center" : null
            }`}
            style={{
              backgroundImage: `url(${backgroundImage})`,
            }}
          >
            <div className="container">
              <div
                className={`flex_cont_top_blog_img  ${
                  isMobile
                    ? "text-align-center mt-0 mb-5"
                    : "float-left text-align-left"
                }`}
              >
                {isMobile ? (
                  <h1 className="font-page-header-home-blog-smb  color-black line-height-18">
                    Implementing Gen AI Chatbots: A Step-by-Step Guide for SMBs
                  </h1>
                ) : (
                  <h1 className="font-page-header-home-blog-smb  color-black">
                    Implementing Gen AI
                    <br /> Chatbots: A Step-by-Step
                    <br /> Guide for SMBs
                  </h1>
                )}
              </div>
            </div>
          </section>
          {isMobile ? <ContentContainerMobile /> : <ContentContainer />}

          {isMobile ? <RelatedBlogs /> : null}
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
function ContentContainer() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  return (
    <section className="w-100 float-left margin-bottom-40 blog_content_parent_container">
      <div className="container">
        <div className="grid_container_goto_second grid_container_blogs_content">
          <div className="grid-left-container-goto blogs_grid_left_container w-100">
            <div className="sticky_container_goto_case">
              <h2 className="font-section-sub-header-small text-decoration-none">
                Table of Contents
              </h2>
              <hr className="underline_blogs_toc mt-0 mb-4"></hr>
              <div className="redirection-flex-container-blog">
                <AnchorLink
                  offset={180}
                  href="#section1"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section1"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section1")}
                >
                  1. Implementing Generative AI chatbots: a step-by-step guide
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section2"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section2"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section2")}
                >
                  2. What challenges do SMBs face as they implement Generative
                  AI solutions?
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section3"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section3"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section3")}
                >
                  3. The boons of no-code platforms for SMBs for implementing
                  GenAI chatbots
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section4"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section4"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section4")}
                >
                  4. Choose a hassle-free way of building your Generative AI
                  chatbot with Workativ
                </AnchorLink>
                <AnchorLink
                  offset={180}
                  href="#section5"
                  className={`font-name-medium-dialog-marketplace ${
                    activeLink === "section5"
                      ? "color-link-blue"
                      : "color-black-resource"
                  }`}
                  onClick={() => handleClick("section5")}
                >
                  5. Conclusion
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Almost every product or solution business has internal and
                customer-facing needs.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A few businesses can say they have an efficient support system
                or seamless internal processes that lessen workloads on the
                agent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                SMBs are challenged to meet customer demands with their existing
                model.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                While their counterparts are using Artificial Intelligence to
                scale customer support capacity, SMBs' approach to transforming
                CX has a mixed outlook.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.freshbooks.com/press/data-research/data-research-majority-of-small-business-owners-will-use-ai">
                  25% of survey respondents
                </a>{" "}
                with Freshbooks say they are testing or using Generative AI to
                outpace competitors.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://cloud.google.com/blog/topics/startups/google-cloud-generative-ai-workshops-for-smbs">
                  Google
                </a>{" "}
                claimed in its report that more and more SMBs are leveraging
                Generative AI for their customer-facing needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Small and medium businesses agree that Generative AI can
                automate business operations, but they fear the high cost of
                in-house solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Besides high costs, there are other types of challenges
                restricting you from building your Gen AI chatbots.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here comes a no-code platform to help you level up your
                Generative AI dreams for customer support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  {" "}
                  Workativ{" "}
                </a>
                provides solutions that minimize your administrative burden,
                including bottom-line expenses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By adopting a no-code platform from Workativ, SMBs can drive
                tangible value for their businesses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s learn how to build your Gen AI chatbots without all the
                hassles efficiently.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Implementing Generative AI chatbots: a step-by-step guide
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ offers a seamless way to build your workflows with
                Generative AI properties and gain the ability to maximize your
                chatbot’s performance by enabling your people to find answers to
                common to complex questions and solve everyday work-related
                problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here are the steps to launch your Generative AI chatbot.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Sign up with Workativ
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The first step to start with your Generative AI chatbot is to
                sign up. Provide correct details and get access to the backend
                of Workativ.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Choose Knowledge AI
                </li>
              </ul>

              <img
                src={section_1}
                className="blog_image_top_bt pl-5"
                alt="Get started with Knowledge AI for Generative AI chatbot development"
              />
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Inside the Workativ Assistant platform, look for Knowledge AI at
                the bottom of the left-side panel. Click Knowledge AI to get the
                power of Generative AI or LLM for your upcoming chatbot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI is highly efficient in using NLP, NLU, and deep
                learning to provide accurate responses for NLP queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI can effectively self-learn and distinguish patterns
                for each query and accurately produce summarized or synthesized
                responses by giving access to your domain-specific knowledge
                bases or articles.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Build your use cases
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Decide which use cases are practical for employee support or
                organizational operational efficiency. For example, if you want
                to augment your existing IT support tasks, you can choose to do
                that.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                There are many mundane IT support tasks, i.e., password reset,
                account unlock, user provision, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Besides IT support, HR operations can be repetitive, including
                Finance, Legal, Marketing or Sales.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Pick any of the business functions, and note down your use
                cases.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Create your knowledge articles or knowledge base
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ gives you extreme flexibility in saving time on dialog
                development.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Chatbot dialog is essential for effective communication or
                coordination to solve a problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI applies LLM power and creates human-like responses
                instantly without the need for you to anticipate scenarios and
                develop specific predefined dialogs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI can understand the context and intent of a query
                sent by a user and surface responses with its built-in ability
                to craft engaging and new content.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                All you need is provide enough resources to support specific use
                cases so that Knowledge AI can fetch the right intent and
                context from a massive pile of knowledge articles and surfaces
                the correct answers.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Integrate knowledge bases or third-party apps
                </li>
              </ul>

              <img
                src={section_2}
                className="blog_image_top_bt pl-5"
                alt="train your Knowledge AI with your preferred choices of resources"
              />
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                With Workativ Knowledge AI, your knowledge bases can be widely
                diverse. Give Knowledge AI access to internal KB or Workativ KB,
                your website, and external KB such as SharePoint, Notion,
                DropBox, Confluence, Slack, and other resource platforms.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Let us know more data you have for your Generative AI platform
                or LLM so you can produce more enriching and accurate responses
                in minimal time.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Upload your files:
                </li>
              </ul>

              <img
                src={section_3}
                className="blog_image_top_bt pl-5"
                alt="enable extended customization with your preferred knowledge articles for knowledge AI"
              />
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Regardless of the type of file format, Workativ allows you to
                upload any files. Pick Excel, docs, PDFs, images, videos, GIFs,
                or anything to upload to the Knowledge AI ecosystem and train
                your bot.
              </p>

              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Customize your workflows
                </li>
              </ul>

              <img
                src={section_4}
                className="blog_image_top_bt pl-5"
                alt="Knowledge AI allows you to choose what following sequential conversation would come using a custom workflow"
              />
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Now enable workflow automation with respect to your use case.
                For example, if you want to provide solutions for printer jams,
                create your flows for this use case. As the flows go, things you
                can do for your users:
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-6">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>You can give an answer</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Jump to another scenario, such as ask your user, ‘was this
                    helpful.’
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Create a ticket for agent handover</span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Decide which scenarios may come up and anticipate the
                subsequential flow.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Test and trial
                </li>
              </ul>

              <img
                src={section_5}
                className="blog_image_top_bt pl-5"
                alt="enable extended customization with your preferred knowledge articles for knowledge AI"
              />
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Experiencing indifferent conversation behavior or prompts
                reflecting otherwise is nothing short of unusual. Say you have a
                flow for the next step, but your bot doesn’t recognize it and
                surfaces, ‘’sorry couldn’t find what you seek’’.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Find a TRY ME button at the bottom of the Workativ platform, and
                check how your flows work.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                You must test conversation flows in a test environment, fix if
                there is any issue, and ensure everything works perfectly.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-sub-header-small">
                  Deploy Generative AI or Knowledge AI chatbot
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                One last notable thing about the Workativ Knowledge AI bot is
                that it allows you to tailor experiences specific to your
                brand’s persona. You set the look and feel just as your brand
                maintains everywhere to build a consistent user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                When everything looks alright, embed your bot in your preferred
                channels. You can integrate your Generative AI chatbot inside
                your Slack, MS Teams, and Website as a chat widget.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-sub-header-small">
                  {" "}
                  Build a feedback loop
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Let your users have a way to connect with you and share their
                experiences about the bot. Send a survey form and collect
                insights. This is a great way to work on gaps and provide
                high-quality chat services.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A no-code platform such as Workativ helps SMBs meet their
                expectations for Generative AI and gain a competitive advantage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you think in-house development is still the best choice,
                let’s help you uncover the truth.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What challenges do SMBs face as they implement Generative AI
                solutions?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Can we say implementing a Generative AI chatbot equals building
                a bridge - with massive manpower, engineering intellect, and
                days of testing and reworks until the final opening?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Indeed yes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To be honest, for SMBs, this kind of iteration is too much to
                pick up.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                There are a couple of factors to look at carefully before you
                jumpstart.
              </p>

              <p className="font-section-normal-text-testimonials-medium line-height-18 color-black">
                Let’s find this significant roadblock for SMBs.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Challenges with closed and open source Generative AI or LLM models                "
              />
              <h3 className="font-section-sub-header-small-bold">
                Model deployment or setup cost:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                At the end of each month, AI bills probably cause you strain.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium color-black">
                Open-source models:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Fine-tuning with proprietary data is ideal for your complete
                customization needs for questions and answers for internal use
                cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, it urges you to take full responsibility for on-premise
                or cloud infrastructure for computing costs. For example,
                platforms such as GPT-4.3 and GPT-Neo are suitable, but
                computing costs are additional.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you look deeper, the costs to build your GenAI models for
                your chatbot could be extremely high because they incur
                additional expenses for components and manpower, including,
              </p>
              <ul className="float-left w-100 blogs_ul_upd ">
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  Hardware costs for platforms such as GPUs or CPUs and AI
                  accelerators{" "}
                </li>
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  Cloud computing costs for platforms such as GCP, AWS, Azure{" "}
                </li>
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  Maintenance costs for hardware
                </li>
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  Integration and deployment costs
                </li>
                <li className="font-section-normal-text-testimonials">
                  Developer costs{" "}
                </li>
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  Data preparation costs
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is so much more for any SMBs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let’s not forget the costs to add to your balance sheet for
                recurring maintenance and more infrastructure units as you
                scale.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium color-black">
                Closed-source models:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is pretty good to go with closed-source models, provided you
                no longer need to take responsibility for computing costs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Commercially available platforms or closed source models such as
                ChatGPT and more advanced versions can incur costs per usage
                basis.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                All vendors charge based on tokens and characters used in inputs
                and outputs. These charges also vary by vendors and the kind of
                capacity they provide.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, PaLM 2 for Chat{" "}
                <a href="https://cloud.google.com/vertex-ai/docs/generative-ai/pricing">
                  costs
                </a>{" "}
                $0.0005/1000 characters for inputs and outputs.{" "}
                <a href="https://openai.com/pricing">GPT-4</a> costs $0.03/1000
                tokens and $0.6/1000 for inputs and outputs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                On top of these monthly or yearly AI bills, if you rely on
                vendors' data systems for data storage, be ready to shell out
                more pennies.
              </p>
              <h3 className="font-section-sub-header-small-bold">
                User experience:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Are you getting complete flexibility or maximizing total value
                from your Generative AI chatbots or interfaces?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is a significant question one can ask oneself so as to
                determine one's level of satisfaction.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium color-black">
                Customization with open-source model:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                You can fine-tune models with your data by accessing the
                underlying infrastructure of open-source models. However, an
                extended level of customization to enable more accurate answers
                is only possible if you have highly skilled in-house AI talent.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium color-black">
                Closed-source model:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                They are available as ‘as is’ on the market. You need to stay
                compliant with whatever little built-in customization is
                available. Although there are upgrades from the company side,
                you should be ready to compromise on limited customization
                capacity. As a result, your Generative AI chatbot can
                underperform if it needs to interact with unfamiliar queries.
              </p>
              <h3 className="font-section-sub-header-small-bold">
                Ethical considerations
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI is known to hallucinate, misinform, or demonstrate
                biased behavior. This is closely intertwined with how you train
                your model with data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is significant to ensure an outstanding effort while
                preparing your data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With an inexperienced AI team and no human in the loop,
                Generative AI solutions tend to exhibit unexpected behavior.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With so much to ponder about implementing your Generative AI
                chatbots or solutions, your strategy to take the Generative AI
                initiative ahead might not take flight.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To your surprise, a no-code platform offers a convenient way to
                build your custom Generative AI chatbots— without all these
                hassles.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The advantage of working with Workativ is that they{" "}
                <a href="https://workativ.com/security">
                  prioritize security on top of everything and help you comply
                  with industry security standards
                </a>{" "}
                such as GDPR, HIPPA, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                  model security
                </a>{" "}
                always comes as a huge priority for everyone. It depends on what
                stringent policies you implement while developing your training
                documents.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The boons of no-code platforms for SMBs for implementing GenAI
                chatbots
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to enhance efficiency for everyday employee
                challenges for common questions, a Generative AI solution can
                effectively help you.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of relying on an ‘as is’ or open-source model, a no-code
                platform conveniently lets you launch your chatbots with
                Generative AI properties.
              </p>
              <h3 className="font-section-sub-header-small">
                The advantages of no-code Generative AI chatbot builder:{" "}
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="the benefits of no-code Generative AI bot builder"
              />

              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  Scale only with a small internal or in-house team to handle
                  Generative AI chatbot functions
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  Incur no extra costs for hardware, server, cloud
                  infrastructure, and licenses
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  Take no responsibility for maintenance works
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Get instant access to the knowledge base or knowledge articles
                  to build for known scenarios
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Build fast and launch readily with your domain-specific
                  knowledge bases
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Improve with inputs from your teams or internal users
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Choose a hassle-free way of building your Generative AI chatbot
                with Workativ
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ, which shows competencies to help businesses gain
                exceptional abilities to enhance employee support with
                conversational AI solutions, gives SMBs a remarkable opportunity
                to build their Generative AI chatbots.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ Assistant
                </a>{" "}
                is a no-code platform with embedded Generative AI properties
                with which business leaders can instantly build their chatbot
                interfaces and gain natural human-like responses for simple to
                complex queries.
              </p>
              <h3 className="font-section-sub-header-small">
                Benefits of working your way with Workativ:{" "}
              </h3>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Generative AI chatbot benefits for SMBs on the Workativ platform "
              />
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-medium">
                  {" "}
                  Help adopt a new product like Generative AI instantly
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ’s no-code platform is easy to use. Regardless of
                whether you possess a massive tech team, Workativ makes it easy
                to get started with your Generative AI chatbot initiative.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-medium">
                  {" "}
                  Create a personalized experience for SMBs
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Create tailored experiences with business use cases specific to
                your needs. As such, you need to turn your HR tasks into
                automated functions, Workativ can do this efficiently.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-medium">
                  {" "}
                  Seamless post-launch support
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Get 24/7 help from our sales support post-launch. To schedule a
                demo,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  connect with us today.
                </a>
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-medium">
                  {" "}
                  Flexible pricing model
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                SMBs can choose the pay-as-you-go model. Workativ’s subscription
                plan can be customized based on your needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Contact sales
                </a>{" "}
                if you want to learn more about your Generative AI options for
                your chatbot.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                One thing we should take note of is that we are living in a
                tightening economy. SMBs struggle to save on their bottom-line
                expenses while also aiming to drive significant revenue growth.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI holds promising potential for everyone to drive
                operational efficiency and gain tangible business values.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Adding to this,{" "}
                <a href="https://www.mckinsey.com/capabilities/mckinsey-digital/our-insights/the-economic-potential-of-generative-ai-the-next-productivity-frontier#key-insights">
                  McKinsey
                </a>{" "}
                confirmed that Generative AI use cases provide massive potential
                to add about $4 trillion annual value to the global economy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                SMBs can drive efficiency by automating customer support tasks
                with Generative AI, which results in cost savings and revenue
                growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you think Generative AI can’t fit your balance sheet budget
                right at this moment, a no-code platform can help you drive your
                Gen AI dreams.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To learn more about how you can implement your Generative
                chatbots cost-effectively, connect with Workativ.{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a demo today.
                </a>
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
            <AuthorBio />
            <RelatedBlogs />
          </div>
        </div>
      </div>
    </section>
  );
}
function ContentContainerMobile() {
  const [activeLink, setActiveLink] = useState("");
  const handleClick = (section) => {
    setActiveLink(section);
  };

  const [tableoFContentsDisplay, settableoFContentsDisplay] = React.useState(
    "hide"
  );
  const onArrowClick = () => {
    if (tableoFContentsDisplay === "show") {
      settableoFContentsDisplay("hide");
    } else {
      settableoFContentsDisplay("show");
    }
  };

  return (
    <section className="w-100 float-left mb-3 mt-4">
      <div className="container">
        <div className="grid_container_goto_second grid_blogs_container_mobile">
          <div className="grid-left-container-goto">
            <div
              className="mobile_accordian_title_goto_case"
              onClick={onArrowClick}
            >
              <h2 className="font-section-sub-header-small mb-3">
                Table of Contents
              </h2>
              {tableoFContentsDisplay === "show" ? (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_close}
                ></img>
              ) : (
                <img
                  className="arrow_goto_case_study_mobile mb-3"
                  src={arrow_open}
                ></img>
              )}
            </div>
            {tableoFContentsDisplay === "show" ? (
              <div className="mobile_accordian_content_goto_case mt-4">
                <div className="redirection-flex-container">
                  <AnchorLink
                    offset={180}
                    href="#section1"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section1"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section1")}
                  >
                    1. Implementing Generative AI chatbots: a step-by-step guide
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section2"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section2"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section2")}
                  >
                    2. What challenges do SMBs face as they implement Generative
                    AI solutions?
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section3"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section3"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section3")}
                  >
                    3. The boons of no-code platforms for SMBs for implementing
                    GenAI chatbots
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section4"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section4"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section4")}
                  >
                    4. Choose a hassle-free way of building your Generative AI
                    chatbot with Workativ
                  </AnchorLink>
                  <AnchorLink
                    offset={180}
                    href="#section5"
                    className={`font-name-medium-dialog-marketplace ${
                      activeLink === "section5"
                        ? "color-link-blue"
                        : "color-black-resource"
                    }`}
                    onClick={() => handleClick("section5")}
                  >
                    5. Conclusion
                  </AnchorLink>
                </div>
              </div>
            ) : null}
          </div>
          <div className="grid-right-container-goto">
            <div className="divs_blogs_upt">
              <p className="font-section-normal-text-testimonials line-height-18">
                Almost every product or solution business has internal and
                customer-facing needs.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A few businesses can say they have an efficient support system
                or seamless internal processes that lessen workloads on the
                agent.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                SMBs are challenged to meet customer demands with their existing
                model.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                While their counterparts are using Artificial Intelligence to
                scale customer support capacity, SMBs' approach to transforming
                CX has a mixed outlook.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://www.freshbooks.com/press/data-research/data-research-majority-of-small-business-owners-will-use-ai">
                  25% of survey respondents
                </a>{" "}
                with Freshbooks say they are testing or using Generative AI to
                outpace competitors.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://cloud.google.com/blog/topics/startups/google-cloud-generative-ai-workshops-for-smbs">
                  Google
                </a>{" "}
                claimed in its report that more and more SMBs are leveraging
                Generative AI for their customer-facing needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Small and medium businesses agree that Generative AI can
                automate business operations, but they fear the high cost of
                in-house solutions.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Besides high costs, there are other types of challenges
                restricting you from building your Gen AI chatbots.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here comes a no-code platform to help you level up your
                Generative AI dreams for customer support.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform/knowledge-ai">
                  {" "}
                  Workativ{" "}
                </a>
                provides solutions that minimize your administrative burden,
                including bottom-line expenses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                By adopting a no-code platform from Workativ, SMBs can drive
                tangible value for their businesses.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Let’s learn how to build your Gen AI chatbots without all the
                hassles efficiently.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section1">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                Implementing Generative AI chatbots: a step-by-step guide
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18">
                Workativ offers a seamless way to build your workflows with
                Generative AI properties and gain the ability to maximize your
                chatbot’s performance by enabling your people to find answers to
                common to complex questions and solve everyday work-related
                problems.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Here are the steps to launch your Generative AI chatbot.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Sign up with Workativ
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                The first step to start with your Generative AI chatbot is to
                sign up. Provide correct details and get access to the backend
                of Workativ.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Choose Knowledge AI
                </li>
              </ul>

              <img
                src={section_1}
                className="blog_image_top_bt pl-5"
                alt="Get started with Knowledge AI for Generative AI chatbot development"
              />
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Inside the Workativ Assistant platform, look for Knowledge AI at
                the bottom of the left-side panel. Click Knowledge AI to get the
                power of Generative AI or LLM for your upcoming chatbot.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI is highly efficient in using NLP, NLU, and deep
                learning to provide accurate responses for NLP queries.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI can effectively self-learn and distinguish patterns
                for each query and accurately produce summarized or synthesized
                responses by giving access to your domain-specific knowledge
                bases or articles.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Build your use cases
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Decide which use cases are practical for employee support or
                organizational operational efficiency. For example, if you want
                to augment your existing IT support tasks, you can choose to do
                that.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                There are many mundane IT support tasks, i.e., password reset,
                account unlock, user provision, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Besides IT support, HR operations can be repetitive, including
                Finance, Legal, Marketing or Sales.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Pick any of the business functions, and note down your use
                cases.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Create your knowledge articles or knowledge base
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ gives you extreme flexibility in saving time on dialog
                development.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Chatbot dialog is essential for effective communication or
                coordination to solve a problem.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI applies LLM power and creates human-like responses
                instantly without the need for you to anticipate scenarios and
                develop specific predefined dialogs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Knowledge AI can understand the context and intent of a query
                sent by a user and surface responses with its built-in ability
                to craft engaging and new content.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                All you need is provide enough resources to support specific use
                cases so that Knowledge AI can fetch the right intent and
                context from a massive pile of knowledge articles and surfaces
                the correct answers.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Integrate knowledge bases or third-party apps
                </li>
              </ul>

              <img
                src={section_2}
                className="blog_image_top_bt pl-5"
                alt="train your Knowledge AI with your preferred choices of resources"
              />
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                With Workativ Knowledge AI, your knowledge bases can be widely
                diverse. Give Knowledge AI access to internal KB or Workativ KB,
                your website, and external KB such as SharePoint, Notion,
                DropBox, Confluence, Slack, and other resource platforms.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Let us know more data you have for your Generative AI platform
                or LLM so you can produce more enriching and accurate responses
                in minimal time.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Upload your files:
                </li>
              </ul>

              <img
                src={section_3}
                className="blog_image_top_bt pl-5"
                alt="enable extended customization with your preferred knowledge articles for knowledge AI"
              />
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Regardless of the type of file format, Workativ allows you to
                upload any files. Pick Excel, docs, PDFs, images, videos, GIFs,
                or anything to upload to the Knowledge AI ecosystem and train
                your bot.
              </p>

              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Customize your workflows
                </li>
              </ul>

              <img
                src={section_4}
                className="blog_image_top_bt pl-5"
                alt="Knowledge AI allows you to choose what following sequential conversation would come using a custom workflow"
              />
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Now enable workflow automation with respect to your use case.
                For example, if you want to provide solutions for printer jams,
                create your flows for this use case. As the flows go, things you
                can do for your users:
              </p>
              <ul className="float-left w-100 blogs_ul_upd list-style-type-none font-section-normal-text-testimonials pl-6">
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>You can give an answer</span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>
                    Jump to another scenario, such as ask your user, ‘was this
                    helpful.’
                  </span>
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 list-style-type-counter">
                  <span>- </span>
                  <span>Create a ticket for agent handover</span>
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Decide which scenarios may come up and anticipate the
                subsequential flow.
              </p>
              <ul className="float-left w-100 blogs_ul_upd  pl-4">
                <li className="color-black font-section-sub-header-small">
                  Test and trial
                </li>
              </ul>

              <img
                src={section_5}
                className="blog_image_top_bt pl-5"
                alt="enable extended customization with your preferred knowledge articles for knowledge AI"
              />
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Experiencing indifferent conversation behavior or prompts
                reflecting otherwise is nothing short of unusual. Say you have a
                flow for the next step, but your bot doesn’t recognize it and
                surfaces, ‘’sorry couldn’t find what you seek’’.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Find a TRY ME button at the bottom of the Workativ platform, and
                check how your flows work.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                You must test conversation flows in a test environment, fix if
                there is any issue, and ensure everything works perfectly.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-sub-header-small">
                  Deploy Generative AI or Knowledge AI chatbot
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                One last notable thing about the Workativ Knowledge AI bot is
                that it allows you to tailor experiences specific to your
                brand’s persona. You set the look and feel just as your brand
                maintains everywhere to build a consistent user experience.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                When everything looks alright, embed your bot in your preferred
                channels. You can integrate your Generative AI chatbot inside
                your Slack, MS Teams, and Website as a chat widget.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-sub-header-small">
                  {" "}
                  Build a feedback loop
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Let your users have a way to connect with you and share their
                experiences about the bot. Send a survey form and collect
                insights. This is a great way to work on gaps and provide
                high-quality chat services.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                A no-code platform such as Workativ helps SMBs meet their
                expectations for Generative AI and gain a competitive advantage.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you think in-house development is still the best choice,
                let’s help you uncover the truth.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section2">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                What challenges do SMBs face as they implement Generative AI
                solutions?
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                Can we say implementing a Generative AI chatbot equals building
                a bridge - with massive manpower, engineering intellect, and
                days of testing and reworks until the final opening?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Indeed yes.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To be honest, for SMBs, this kind of iteration is too much to
                pick up.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                There are a couple of factors to look at carefully before you
                jumpstart.
              </p>

              <p className="font-section-normal-text-testimonials-medium line-height-18 color-black">
                Let’s find this significant roadblock for SMBs.
              </p>
              <img
                src={section_6}
                className="blog_image_top_bt"
                alt="Challenges with closed and open source Generative AI or LLM models                "
              />
              <h3 className="font-section-sub-header-small-bold">
                Model deployment or setup cost:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                At the end of each month, AI bills probably cause you strain.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium color-black">
                Open-source models:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Fine-tuning with proprietary data is ideal for your complete
                customization needs for questions and answers for internal use
                cases.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However, it urges you to take full responsibility for on-premise
                or cloud infrastructure for computing costs. For example,
                platforms such as GPT-4.3 and GPT-Neo are suitable, but
                computing costs are additional.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                If you look deeper, the costs to build your GenAI models for
                your chatbot could be extremely high because they incur
                additional expenses for components and manpower, including,
              </p>
              <ul className="float-left w-100 blogs_ul_upd ">
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  Hardware costs for platforms such as GPUs or CPUs and AI
                  accelerators{" "}
                </li>
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  Cloud computing costs for platforms such as GCP, AWS, Azure{" "}
                </li>
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  Maintenance costs for hardware
                </li>
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  Integration and deployment costs
                </li>
                <li className="font-section-normal-text-testimonials">
                  Developer costs{" "}
                </li>
                <li className="font-section-normal-text-testimonials">
                  {" "}
                  Data preparation costs
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is so much more for any SMBs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Let’s not forget the costs to add to your balance sheet for
                recurring maintenance and more infrastructure units as you
                scale.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium color-black">
                Closed-source models:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is pretty good to go with closed-source models, provided you
                no longer need to take responsibility for computing costs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Commercially available platforms or closed source models such as
                ChatGPT and more advanced versions can incur costs per usage
                basis.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                All vendors charge based on tokens and characters used in inputs
                and outputs. These charges also vary by vendors and the kind of
                capacity they provide.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                For example, PaLM 2 for Chat{" "}
                <a href="https://cloud.google.com/vertex-ai/docs/generative-ai/pricing">
                  costs
                </a>{" "}
                $0.0005/1000 characters for inputs and outputs.{" "}
                <a href="https://openai.com/pricing">GPT-4</a> costs $0.03/1000
                tokens and $0.6/1000 for inputs and outputs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                On top of these monthly or yearly AI bills, if you rely on
                vendors' data systems for data storage, be ready to shell out
                more pennies.
              </p>
              <h3 className="font-section-sub-header-small-bold">
                User experience:
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Are you getting complete flexibility or maximizing total value
                from your Generative AI chatbots or interfaces?
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                This is a significant question one can ask oneself so as to
                determine one's level of satisfaction.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium color-black">
                Customization with open-source model:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                You can fine-tune models with your data by accessing the
                underlying infrastructure of open-source models. However, an
                extended level of customization to enable more accurate answers
                is only possible if you have highly skilled in-house AI talent.
              </p>
              <h4 className="font-section-normal-text-testimonials-medium color-black">
                Closed-source model:
              </h4>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                They are available as ‘as is’ on the market. You need to stay
                compliant with whatever little built-in customization is
                available. Although there are upgrades from the company side,
                you should be ready to compromise on limited customization
                capacity. As a result, your Generative AI chatbot can
                underperform if it needs to interact with unfamiliar queries.
              </p>
              <h3 className="font-section-sub-header-small-bold">
                Ethical considerations
              </h3>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                Generative AI is known to hallucinate, misinform, or demonstrate
                biased behavior. This is closely intertwined with how you train
                your model with data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                It is significant to ensure an outstanding effort while
                preparing your data.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With an inexperienced AI team and no human in the loop,
                Generative AI solutions tend to exhibit unexpected behavior.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                With so much to ponder about implementing your Generative AI
                chatbots or solutions, your strategy to take the Generative AI
                initiative ahead might not take flight.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                To your surprise, a no-code platform offers a convenient way to
                build your custom Generative AI chatbots— without all these
                hassles.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                The advantage of working with Workativ is that they{" "}
                <a href="https://workativ.com/security">
                  prioritize security on top of everything and help you comply
                  with industry security standards
                </a>{" "}
                such as GDPR, HIPPA, etc.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 ">
                However,{" "}
                <a href="https://workativ.com/conversational-ai-platform/blog/llm-security-service-desk">
                  model security
                </a>{" "}
                always comes as a huge priority for everyone. It depends on what
                stringent policies you implement while developing your training
                documents.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section3">
              <h2 className="font-section-sub-header-blog color-heading-blogs">
                The boons of no-code platforms for SMBs for implementing GenAI
                chatbots
              </h2>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you want to enhance efficiency for everyday employee
                challenges for common questions, a Generative AI solution can
                effectively help you.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                Instead of relying on an ‘as is’ or open-source model, a no-code
                platform conveniently lets you launch your chatbots with
                Generative AI properties.
              </p>
              <h3 className="font-section-sub-header-small">
                The advantages of no-code Generative AI chatbot builder:{" "}
              </h3>
              <img
                src={section_7}
                className="blog_image_top_bt"
                alt="the benefits of no-code Generative AI bot builder"
              />

              <ul className="float-left w-100 blogs_ul_upd font-section-normal-text-testimonials">
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  Scale only with a small internal or in-house team to handle
                  Generative AI chatbot functions
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  Incur no extra costs for hardware, server, cloud
                  infrastructure, and licenses
                </li>
                <li class="font-section-normal-text-testimonials line-height-18 ">
                  Take no responsibility for maintenance works
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Get instant access to the knowledge base or knowledge articles
                  to build for known scenarios
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Build fast and launch readily with your domain-specific
                  knowledge bases
                </li>
                <li class="font-section-normal-text-testimonials line-height-18">
                  Improve with inputs from your teams or internal users
                </li>
              </ul>
            </div>
            <div className="divs_blogs_upt float-left" id="section4">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Choose a hassle-free way of building your Generative AI chatbot
                with Workativ
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                Workativ, which shows competencies to help businesses gain
                exceptional abilities to enhance employee support with
                conversational AI solutions, gives SMBs a remarkable opportunity
                to build their Generative AI chatbots.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                <a href="https://workativ.com/conversational-ai-platform">
                  Workativ Assistant
                </a>{" "}
                is a no-code platform with embedded Generative AI properties
                with which business leaders can instantly build their chatbot
                interfaces and gain natural human-like responses for simple to
                complex queries.
              </p>
              <h3 className="font-section-sub-header-small">
                Benefits of working your way with Workativ:{" "}
              </h3>
              <img
                src={section_8}
                className="blog_image_top_bt"
                alt="Generative AI chatbot benefits for SMBs on the Workativ platform "
              />
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-medium">
                  {" "}
                  Help adopt a new product like Generative AI instantly
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Workativ’s no-code platform is easy to use. Regardless of
                whether you possess a massive tech team, Workativ makes it easy
                to get started with your Generative AI chatbot initiative.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-medium">
                  {" "}
                  Create a personalized experience for SMBs
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Create tailored experiences with business use cases specific to
                your needs. As such, you need to turn your HR tasks into
                automated functions, Workativ can do this efficiently.
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-medium">
                  {" "}
                  Seamless post-launch support
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                Get 24/7 help from our sales support post-launch. To schedule a
                demo,{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  connect with us today.
                </a>
              </p>
              <ul className="float-left w-100 blogs_ul_upd pl-4">
                <li className="color-black font-section-normal-text-medium">
                  {" "}
                  Flexible pricing model
                </li>
              </ul>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                SMBs can choose the pay-as-you-go model. Workativ’s subscription
                plan can be customized based on your needs.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18 pl-5">
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Contact sales
                </a>{" "}
                if you want to learn more about your Generative AI options for
                your chatbot.
              </p>
            </div>
            <div className="divs_blogs_upt float-left" id="section5">
              <h2 class="font-section-sub-header-blog color-heading-blogs">
                Conclusion
              </h2>

              <p className="font-section-normal-text-testimonials line-height-18 ">
                One thing we should take note of is that we are living in a
                tightening economy. SMBs struggle to save on their bottom-line
                expenses while also aiming to drive significant revenue growth.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Generative AI holds promising potential for everyone to drive
                operational efficiency and gain tangible business values.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                Adding to this,{" "}
                <a href="https://www.mckinsey.com/capabilities/mckinsey-digital/our-insights/the-economic-potential-of-generative-ai-the-next-productivity-frontier#key-insights">
                  McKinsey
                </a>{" "}
                confirmed that Generative AI use cases provide massive potential
                to add about $4 trillion annual value to the global economy.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                SMBs can drive efficiency by automating customer support tasks
                with Generative AI, which results in cost savings and revenue
                growth.
              </p>
              <p className="font-section-normal-text-testimonials line-height-18">
                If you think Generative AI can’t fit your balance sheet budget
                right at this moment, a no-code platform can help you drive your
                Gen AI dreams.
              </p>

              <p className="font-section-normal-text-testimonials line-height-18">
                To learn more about how you can implement your Generative
                chatbots cost-effectively, connect with Workativ.{" "}
                <a href="https://workativ.com/conversational-ai-platform/demo">
                  Schedule a demo today.
                </a>
              </p>
            </div>

            <BlogCta
              ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
              ButtonText="Book a Demo"
              isColor="black"
              backgroundImage={cta_1}
              mobileBackgroundImage={cta_1_mob}
            />
          </div>
        </div>
      </div>
      <AuthorBio />
    </section>
  );
}
export const AuthorBio = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="w-100 float-left author_bio_parent_cont">
      <div className="author_bio_flex_container">
        {isMobile ? (
          <div className="author_bio_flex_left align-self-start">
            <img src={author_mobile} className="width-40"></img>

            <div className="align-self-center">
              <h2 className="font-section-sub-header-small-home mt-3">
                Deepa Majumder
              </h2>
              <h4 className="font-section-normal-text-testimonials">
                Content Writer
              </h4>{" "}
            </div>
          </div>
        ) : (
          <div className="author_bio_flex_left align-self-start">
            <img src={author}></img>
            <h2 className="font-section-sub-header-small-home mt-3">
              Deepa Majumder
            </h2>
            <h4 className="font-section-normal-text-testimonials">
              Content Writer
            </h4>{" "}
          </div>
        )}

        <div className="author_bio_flex_right w-100">
          <p className="font-section-normal-text-testimonials line-height-18">
            Deepa Majumder is a writer who nails the art of crafting bespoke
            thought leadership articles to help business leaders tap into rich
            insights in their journey of organization-wide digital
            transformation. Over the years, she has dedicatedly engaged herself
            in the process of continuous learning and development across
            business continuity management and organizational resilience.{" "}
          </p>
          <p className="font-section-normal-text-testimonials line-height-18 pb-0 mb-0">
            Her pieces intricately highlight the best ways to transform employee
            and customer experience. When not writing, she spends time on
            leisure activities.
          </p>
        </div>
      </div>
    </section>
  );
};
const RelatedBlogs = () => {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <div
      className={`most_popular_links ${isMobile ? "mt-4 container" : "mt-5"}`}
    >
      <div className="most_popular_header font-section-sub-header">
        Related Articles
      </div>
      <div className="most_popular_ul">
        <ul
          className="section__ul font-section-normal-text ul_related_blogs_upd"
          style={{ width: "100%", float: "left", borderBottom: "none" }}
        >
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/roi-chatbot-automation-it-service-desk"
              className="font-section-normal-text-testimonials"
            >
              The ROI of Using Chatbot and Automation with Your IT Service Desk​
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/ai-it-service-management"
              className="font-section-normal-text-testimonials"
            >
              AI in ITSM: Unlocking the Value of AI for IT Service Management
            </a>
          </li>
          <li>
            <a
              href="https://workativ.com/conversational-ai-platform/blog/onboarding-challenges-it-service-desk-face-with-new-agents"
              className="font-section-normal-text-testimonials"
            >
              {" "}
              Onboarding Challenges IT Service Desk Face with New Agents
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
